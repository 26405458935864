import * as rpc from 'const/rpc';
import * as site from 'const/site';
import { Service as MetricService } from 'modules/common/MetricService';

import { BackendError } from './backend/BackendError/BackendError';

/**
 * Преобразование ошибок RPC в удобочитаемый текст с последующим вызовом исключения.
 * @param request
 * @param response Ответ от сервера.
 */
const parseError = (request, response) => {
  if (!response.error) {
    return response.result;
  }

  const code = parseInt(response.error.code, 10);
  const error = new BackendError(response.error.message, code);

  switch (code) {
    case rpc.UNAUTHORIZED_ERROR:
      error.message = 'Доступ запрещен';
      break;

    case rpc.SERVER_ERROR_HAS_OCCURRED:
      error.message =
        'Возникла серверная ошибка. Пожалуйста, повторите операцию через несколько минут.';
      break;

    case rpc.SMS_SEND_ERROR:
      error.message = 'Ошибка отправки SMS';
      break;

    case rpc.SMS_NOT_ALLOWED:
      error.message = 'Отправка SMS запрещена';
      break;

    case rpc.SMS_ALREADY_CONFIRMED:
      error.message = 'Операция уже подтверждена';
      break;

    case rpc.SMS_MAX_SMS_EXCEED:
      error.message = 'Превышен порог количества отправки SMS';
      break;

    case rpc.SMS_MAX_TRIES_EXCEED:
      error.message = `Превышено количество запросов SMS. Чтобы запросить SMS еще раз позвоните ${site.PHONE_NUMBER} (звонок по России бесплатный) или напишите в чат на сайте`;
      break;

    case rpc.SMS_WRONG_CONFIRMATION:
      error.message = 'Некорректный SMS-код';
      break;

    case rpc.NO_SELECTED_LOAN:
      error.message = 'Не выбраны параметры займа';
      break;

    case rpc.METHOD_NOT_FOUND:
      error.message = 'Сервер не распознал запрос';
      break;

    case rpc.WRONG_PROMOCODE:
      error.message = 'Промокод недействителен';
      break;

    case rpc.WRONG_PROMOCODE_TEXT:
      error.message =
        'Указан неверный промокод. Проверьте правильность написания промокода или свяжитесь с нашей клиентской поддержкой';
      break;

    case rpc.WRONG_PROMOCODE_DATE:
      error.message = 'Срок действия этого промокода истек';
      break;

    case rpc.ESIA:
      error.message = 'Ошибка при получении информации с портала Госуслуг. Попробуйте еще раз';
      break;

    case rpc.LOAN_REMOVE:
      error.message = 'Ошибка при изменении условий займа';
      break;

    case rpc.PASSPORT_UPDATE_FAILED:
      error.message = 'Ошибка при обновлении паспортных данных';
      break;

    case rpc.RPC_PASSPORT_INVALID_ERROR:
      error.message = 'Указаны недействительные паспортные данные';
      break;

    case rpc.RPC_UNAUTHORIZED_ERROR:
      error.message = 'Доступ запрещен';
      break;

    case rpc.RPC_LOAN_CREATE_ERROR:
      error.message = 'Запросите SMS для подписания повторно';
      break;

    case rpc.RPC_LOAN_NOT_FOUND:
      error.message = 'Заём не найден';
      break;

    case rpc.RPC_LOAN_PAYOUT_ERROR:
      error.message = `Ошибка выплаты денежных средств. Пожалуйста, позвоните нам: ${site.PHONE_NUMBER} (звонок по России бесплатный) или напишите в чат на сайте.`;
      break;

    case rpc.RPC_PROLONGATION_AMOUNT_TO_PAY_ERROR:
      error.message = 'Некорректная сумма для продления займа';
      break;

    case rpc.RPC_BIND_CARD_ERROR:
      error.message = 'Ошибка привязки карты';
      break;

    case rpc.RPC_CARD_NOT_FOUND:
      error.message = 'Карта не найдена';
      break;

    case rpc.RPC_NO_INN_AND_SNILS_ERROR:
      error.message = 'Не заполнены ИНН и СНИЛС';
      break;

    case rpc.PASSPORT_REQUIRED_ERROR:
      error.message = 'Требуется проверка паспорта';
      break;

    case rpc.RPC_SMS_SEND_ERROR:
      error.message = 'Ошибка отправки SMS';
      break;

    case rpc.RPC_SMS_NOT_ALLOWED:
      error.message = 'Отправка SMS запрещена';
      break;

    case rpc.RPC_SMS_ALREADY_CONFIRMED:
      error.message = 'Операция уже подтверждена';
      break;

    case rpc.RPC_SMS_MAX_SMS_EXCEED:
      error.message = 'Превышен порог количества отправки SMS';
      break;

    case rpc.RPC_SMS_MAX_TRIES_EXCEED:
      error.message = 'Превышен порог количества запроса SMS';
      break;

    case rpc.RPC_SMS_WRONG_CONFIRMATION:
      error.message = 'Некорректный SMS-код';
      break;

    case rpc.RPC_PROLONGATION_NOT_FOUND:
      error.message = 'Пролонгация не найдена';
      break;

    case rpc.RPC_PROLONGATION_IS_NOT_CONFIRMED:
      error.message = 'Пролонгация не подтверждена SMS-кодом';
      break;

    case rpc.RPC_PROLONGATION_CREATION_FAIL:
      error.message = 'Ошибка при создании пролонгации';
      break;

    case rpc.RPC_LOGIN_ERROR:
      error.message = `Ранее вы указали другие паспортные данные для этого номера телефона. Чтобы узнать, как изменить личные данные или номер, пройдите по ссылке или позвоните ${site.PHONE_NUMBER} (звонок по России бесплатный) или напишите в чат на сайте.`;
      break;

    case rpc.RPC_QUICK_LOGIN_TOKEN_NOT_FOUND:
      error.message = 'Некорректный токен авторизации';
      break;

    case rpc.RPC_ACCEPTANCE_PHONE_NOT_FOUND:
      error.message = 'Номер телефона не найден';
      break;

    case rpc.RPC_PAYMENT_IS_TOO_BIG_ERROR:
      error.message = 'Сумма оплаты больше задолженности';
      break;

    case rpc.RPC_PAYMENT_ERROR:
      error.message = 'Ошибка при запросе данных';
      break;

    case rpc.RPC_CREDIT_CARD_DOES_NOT_BELONG_TO_CLIENT_ERROR:
      error.message = 'Карта не привязана';
      break;

    case rpc.RPC_DEVICE_ID_NOT_FOUND_ERROR:
      error.message = 'Ошибка при определении идентификатора';
      break;

    case rpc.RPC_NO_EMAIL_OR_MOBILE_PHONE_ERROR:
      error.message = 'Не указаны либо почта, либо мобильный телефон';
      break;

    case rpc.RPC_UPDATE_CLIENT_ERROR:
      error.message = 'Ошибка при обновлении данных клиента';
      break;

    case rpc.RPC_LOAN_IS_ALREADY_PAID:
      error.message = 'Ваш заём полностью оплачен';
      break;

    case rpc.RPC_CHANGE_PHONE_SMS_ERROR:
      error.message = 'Ошибка при отправке SMS-кода';
      break;

    case rpc.RPC_ULOGIN_ERROR:
      error.message = 'Ошибка при привязке социальной сети';
      break;

    case rpc.RPC_WRONG_PROMO_PRIZE:
      error.message = 'Необходимо выбрать приз';
      break;

    case rpc.RPC_PAYMENT_IS_NOT_ALLOWED:
      error.message = 'Оплата займа доступна только клиентам До зарплаты';
      break;

    case rpc.RPC_PASSPORT_DOUBLE_ERROR:
      error.message = 'Такой паспорт уже существует';
      break;

    // case rpc.RPC_PASSPORT_NO_TIED_PHONE_ERROR:
    //   error.message = `Наша система распознала, что данные серия и номер паспорта не соответствуют номеру телефона, указанному вами при регистрации.
    //     Для защиты ваших данных, нам необходимо провести дополнительную идентификацию. Пожалуйста, обратитесь в службу техподдержки.`;
    //   break;

    case rpc.RPC_SOCIAL_ERROR:
      error.message = 'Ошибка при привязке социальной сети';
      break;

    case rpc.RPC_NO_METHOD:
      error.message = 'Сервер не распознал запрос';
      break;

    case rpc.RPC_INVALID_PARAMETERS:
      error.message = 'Ошибка при отправке данных';
      break;

    case rpc.RPC_INVALID_PROMO_CODE:
      error.message = 'Промокод недействителен';
      break;

    case rpc.RPC_REPEAT_LOAN_PROMO_CODE_CONDITION_ERROR:
      error.message =
        'По данному промокоду вы можете получить бесплатный займ на сумму до 30000 руб. и срок до 30 дней';
      break;

    case rpc.RPC_NEW_LOAN_PROMO_CODE_CONDITION_ERROR:
      error.message =
        'По данному промокоду вы можете получить бесплатные 7 дней на сумму займа до 5000 руб. и срок до 20 дней';
      break;

    case rpc.RPC_LOAN_TRANSFER_TO_CORONA_CARD_ERROR:
      error.message = 'Ошибка при переводе на карту Золотая Корона';
      break;

    case rpc.RPC_CORONA_BIND_CARD_ERROR:
      error.message = 'Ошибка при привязке карты Золотая Корона';
      break;

    case rpc.RPC_CLIENT_DOES_NOT_HAVE_CORONA_CARD:
      error.message = 'Нет привязанной карты Золотая Корона';
      break;

    case rpc.RPC_KORONA_PAY_ERROR:
      error.message = 'Ошибка перевода';
      break;

    case rpc.RPC_PAYOUT_IS_NOT_ALLOWED:
      error.message = 'Ошибка при выплате займа';
      break;

    case rpc.RPC_PAYOUT_TINKOFF_DISALLOWED:
      error.message =
        'Уважаемый клиент, предлагаем Вам воспользоваться другой банковской картой для получения займа';
      break;

    case rpc.TAKE_LOAN_TRIES_EXCEED:
      error.message =
        'Мы обнаружили подозрительную активность в вашем аккаунте. В целях вашей безопасности подача заявки на заём временно заблокирована. Подать новую заявку вы сможете ';
      break;

    case rpc.RPC_ADDITIONAL_SERVICES_BROKERAGE_NOT_FOUND:
      error.message = 'Подключаемая услуга по брокериджу не найдена';
      break;

    case rpc.SMS_CODE_ALREADY_SENT:
      error.message =
        'Превышен лимит запроса SMS. Пожалуйста, запросите SMS код для авторизации в Личном кабинете через несколько минут.';
      break;

    case rpc.USER_NOT_FOUND:
      error.message = 'Пользователь не найден.';
      break;

    case rpc.WRONG_PASSWORD:
      error.message = 'Неверный пароль или пин-код.';
      break;

    case rpc.WRONG_USER_TYPE:
      error.message = 'Неверный тип пользователя.';
      break;

    case rpc.LOGIN_TRIES_EXCEEDED:
      error.message =
        'Превышен лимит ввода пароля, для входа в личный кабинет необходимо создать новый.';
      break;

    case rpc.SERVER_LOGIN_ERROR:
      error.message = 'Ошибка сервера авторизации.';
      break;

    case rpc.HEIC_ATTACHMENT_ERROR:
      error.message =
        'Формат .HEIC с устройств iPhone не поддерживается. Пожалуйста, преобразуйте файл в формат .jpeg, .png или .gif. Или заполните форму с вашего телефона и загрузите фото заявления напрямую из галереи iPhone';
      break;

    case rpc.LOAN_EXISTS_ERROR:
      error.message = 'Ошибка при создании заявки';
      break;

    case rpc.LOAN_TEEN_AGE_ERROR:
      error.message = 'Ошибка при создании заявки';
      break;

    case rpc.PASSPORT_VALIDATE_RFM_ERROR:
      error.message = 'Ошибка при создании заявки';
      break;

    case rpc.CAPTCHA_ERROR:
      error.message = 'Ошибка при создании заявки';
      break;

    case rpc.LOAN_RESTRUCTURING_NOT_ALLOWED:
      error.message = 'Ошибка реструктуризации. Реструктуризация не доступна.';
      break;

    case rpc.BANK_BIK_CHECK_FAILED:
      error.message = 'БИК не найден. Введите корректный БИК.';
      break;

    case rpc.RPC_CLIENT_FULLNAME_DOES_NOT_MATCH_EXCEPTION:
      error.message = 'Некорректные данные ФИО в анкете.';
      break;

    case rpc.RPC_INVALID_ACCOUNT_NUMBER_EXCEPTION:
      error.message = 'Неправильный номер счета.';
      break;

    case rpc.RPC_INVALID_FILE:
      error.message = 'Ошибка при отправки файла, попробуйте другой файл формата JPG или PNG';
      break;

    case rpc.RPC_INVALID_INN:
      error.message =
        'Проверьте, пожалуйста, корректность заполнения Ваших персональных данных. И направьте запрос повторно';
      break;

    case rpc.RPC_SBP_BANK_NOT_FOUND:
      error.message =
        'Мы не смогли определить банки, в которых вам доступен перевод через Систему быстрых платежей (СБП). Пожалуйста, повторите попытку позже или верните средства по банковским реквизитам.';
      break;

    case rpc.RPC_THERE_WAS_AN_ERROR_DURING_CASH_WITHDRAWAL:
      error.message =
        'Возникла ошибка при выдаче денег. Выберите или добавьте заново карту и попробуйте снова получить деньги.';
      break;

    default:
      error.message = `Чтобы устранить ошибку, нам нужно уточнить, на каком этапе она возникла. Пожалуйста, позвоните нам: ${site.PHONE_NUMBER} (звонок по России бесплатный) или напишите в чат на сайте.`;
  }

  MetricService.onErrorBackend(code);
  MetricService.onErrorShown(error.message);

  throw error;
};

// eslint-disable-next-line import/no-default-export
export default parseError;
