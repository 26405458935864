import moment from 'moment';
import helpers from 'utils/Helpers';
import * as regexp from 'const/regexp';
import { VJFValidator } from 'mobx-react-form';

function isEmptyValue(value) {
  return [null, undefined, ''].includes(value);
}

export function required({ field }) {
  const isValid = !isEmptyValue(String(field.value).trim());
  return [isValid, 'Обязательное поле'];
}

// default no value for select = -1
export function requiredSelect({ field }) {
  const isValid = !isEmptyValue(String(field.value).trim()) && field.value !== -1;

  return [isValid, 'Обязательное поле'];
}

// default no value for select = -1
export function requiredChecked({ field }) {
  return [Boolean(field.value), 'Обязательное поле'];
}

export function requiredIfTargetFalse(target: string): VJFValidator {
  return ({ field, form }) => {
    if (form.$(target).value !== false) {
      return [true];
    }

    return required({ field });
  };
}

export function requiredSelectIfTargetFalse(target) {
  return ({ field, form }) => {
    if (form.$(target).value !== false) {
      return [true];
    }

    return requiredSelect({ field });
  };
}

export function maxLength(length = 5) {
  return ({ field }) => {
    const value = String(field.get('value')).replace(/\s(?=\s)/g, '');
    const isValid = isEmptyValue(value) || value.length <= length;

    return [isValid, `Длина поля должна быть не больше ${length} символов`];
  };
}

export function minLength(length = 5) {
  return ({ field }) => {
    const value = String(field.get('value')).replace(/\s(?=\s)/g, '');
    const isValid = isEmptyValue(value) || (value && value.length >= length);

    return [isValid, `Длина поля должна быть не меньше ${length} символов`];
  };
}

export function cyrillic({ field }) {
  const isCyrillic = !String(field.value).replace(/[\u0400-\u04FF\-\s]+/g, '');
  const isValid = isEmptyValue(field.value) || isCyrillic;

  let fieldName = '';
  switch (field.name) {
    case 'firstName':
      fieldName = 'имени';
      break;
    case 'lastName':
      fieldName = 'фамилии';
      break;
    case 'patronymic':
    case 'secondName':
      fieldName = 'отчества';
      break;
    default:
      break;
  }

  let message: string = 'Пожалуйста, используйте кириллицу';

  if (fieldName) {
    message = `${message} для указания ${fieldName}`;
  }

  return [isValid, message];
}

export const textLine: VJFValidator = ({ field }) => {
  const message = `Поле не должно содержать символов ';', '.' или '/'`;
  const isValid = !/[.;/]/.test(field.value);
  return [isValid, message];
};

export function email({ field }) {
  const isValid =
    isEmptyValue(field.value) || (field.value && regexp.EMAIL_ADDRESS.test(field.value));

  return [isValid, 'Некорректный e-mail'];
}

export function cellPhoneNumber({ field }) {
  const isValid =
    isEmptyValue(field.value) ||
    (field.value && regexp.MOBILE_PHONE.test(helpers.onlyNumbers(field.value)));

  return [isValid, 'Некорректный номер телефона'];
}

export function strictCellPhoneNumber({ field }) {
  const isValid =
    isEmptyValue(field.value) ||
    (field.value && regexp.STRICT_MOBILE_PHONE.test(helpers.onlyNumbers(field.value)));

  return [isValid, 'Некорректный номер телефона'];
}

export function validDate({ field }) {
  const isValid =
    helpers.onlyNumbers(field.value).length === 8 && moment(field.value, 'DD.MM.YYYY').isValid();

  return [isValid, 'Некорректная дата'];
}

export function notFutureDate({ field }) {
  const isValid =
    helpers.onlyNumbers(field.value).length === 8 &&
    moment(field.value, 'DD.MM.YYYY').isValid() &&
    moment(field.value, 'DD.MM.YYYY').startOf('day').isSameOrBefore(moment().startOf('day'));

  return [isValid, 'Дата не может быть в будущем'];
}

export function minAge(age = 18) {
  return ({ field }) => {
    const date = moment(field.value, 'DD.MM.YYYY').startOf('day');
    const dateDiff = moment().startOf('day').diff(date, 'years');

    const isValid = dateDiff >= age;

    return [isValid, `Вам должно быть не менее ${age}`];
  };
}

export function maxAge(age = 100) {
  return ({ field }) => {
    const date = moment(field.value, 'DD.MM.YYYY').startOf('day');
    const dateDiff = moment().startOf('day').diff(date, 'years');

    const isValid = dateDiff <= age;

    return [isValid, `Вам должно быть не более ${age}`];
  };
}

export function passport({ field }) {
  const isValid = regexp.PASSPORT.test(helpers.onlyNumbers(field.value));

  return [isValid, 'Некорректный номер паспорта'];
}

export function passportIssueDate(target) {
  return ({ field, form }) => {
    if (!form.$(target).isValid && form.$(target).value.length > 0) {
      return [false, 'Введите корректную дату рождения'];
    }

    const birthDate = moment(form.$(target).value, 'DD.MM.YYYY');
    const issueDate = moment(field.value, 'DD.MM.YYYY');

    if (birthDate.startOf('day').isSameOrAfter(issueDate.startOf('day'))) {
      return [false, 'Некорректная дата. Проверьте дату рождения и дату выдачи паспорта'];
    }

    const issueYearsAgo = moment().diff(issueDate, 'years');
    const birthYearsAgo = moment().diff(birthDate, 'years');
    const issueAge = birthYearsAgo - issueYearsAgo;

    if (
      (birthYearsAgo >= 20 && birthYearsAgo < 45 && (issueAge < 20 || issueAge >= 45)) ||
      (birthYearsAgo >= 45 && issueAge < 45)
    ) {
      return [false, 'У вас закончился срок действия паспорта'];
    }

    if (issueAge < 14) {
      return [false, 'Паспорт РФ выдается в возрасте 14 лет'];
    }

    return [true, ''];
  };
}

export function passportSubCode({ field }) {
  const isValid = regexp.PASSPORT_SUBCODE.test(helpers.onlyNumbers(field.value));

  return [isValid, 'Некорректный код подразделения'];
}

export function notEqualToField(target, errorText = 'Поля должны различаться'): VJFValidator {
  return ({ field, form }) => {
    const isValid = field.value === '' || form.$(target).value !== field.value;

    return [isValid, errorText];
  };
}

export function sameAs(fieldName: string): VJFValidator {
  return ({ field, form }) => {
    const isValid = field.get('value') === form.$(fieldName).get('value');
    return [isValid, 'Значения полей не совпадают'];
  };
}

export const passwordSet = [required, minLength(4), maxLength(20)] as const;
export const shortPasswordSet = [required, minLength(4), maxLength(4)] as const;

export function maxFileSize(maxSize: number): VJFValidator {
  const message = 'Превышен максимальный размер файла';

  return ({ field }) => {
    let isValid: boolean = true;

    if (field.value) {
      const file = field.value as File;
      isValid = file.size < maxSize;
    }

    return [isValid, message];
  };
}

export const imageFile: VJFValidator = ({ field }) => {
  const message = 'Выбранный файл должен быть изображением';
  let isValid: boolean = true;

  if (field.value) {
    const file = field.value as File;
    // Жуткий костыль, но нет решения для декодинга .heic браузерами
    isValid = file.type.indexOf('image') === 0 || file.name.includes('.heic');
  }
  return [isValid, message];
};

export const bik: VJFValidator = ({ field }) => {
  const message = 'Неверный формат БИК';
  const isValid = /^\d{9}$/.test(field.value);
  return [isValid, message];
};

export const accountNumber: VJFValidator = ({ field }) => {
  if (!/^\d{5} \d{3} \d{1} \d{4} \d{7}$/.test(field.value)) {
    return [false, 'Номер должен содержать 20 цифр'];
  }

  if (!/^40817 810 \d{1} \d{4} \d{7}$/.test(field.value)) {
    return [false, 'Номер должен начинаться с 40817 810'];
  }

  return [true, ''];
};

export const loanNumber: VJFValidator = ({ field }) => {
  const message = 'Неверный формат номера займа';
  const isValid = /^\d+-\d+$/.test(field.value);
  return [isValid, message];
};

export const inn: VJFValidator = ({ field }) => {
  const value = helpers.onlyNumbers(field.value);

  if (!value) {
    return [true, ''];
  }

  const isValidSnils = /^\d{11}$/.test(value);
  const isValidInn = /^\d{12}$/.test(value);

  return [
    isValidSnils || isValidInn,
    'Необходимо внести цифровой код ИНН (12 цифр) или СНИЛС (11 цифр)',
  ];
};
