import { CustomError } from 'utils/errors';

export class BackendError extends CustomError {
  public code: number;

  public data?;

  constructor(message: string, code: number) {
    super(`${code} : ${message}`);
    this.code = code;
  }
}
