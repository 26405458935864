import Api from './Api';

/**
 * Тип соглашения для сортировки по блокам.
 */
export enum BlockType {
  One = 1,
  Two = 2,
}

export declare namespace GetLoanOrder {
  type Request = null;

  type Response = {
    url?: string;
    agreementUrl?: string;
    scheduleUrl?: string;
    serviceAgreementUrl?: string;
    isActiveService?: boolean;
    sharedLoanAgreementUrl?: string;

    /**
     * Флаг работает в рамках задачи https://jira.devim.team/browse/DWN-14278.
     */
    dwn13750?: boolean;
    [key: string]: any;
  };
}

export declare namespace CreateBySelectedLoan {
  type Request = {
    offset: number;
    agreements?: Record<string, boolean>;
    confirmationCode: string;
    loanApplicationDraftId?: string;
  };

  type Response = true;
}

export declare namespace SendConfirmationSms {
  type Request = null;

  type Response = true;
}

export declare namespace Sign {
  type Request = null;

  type Response = true;
}

export declare namespace GetAgreements {
  type Request = null;

  type Agreement = {
    /**
     * key of agreement
     */
    type: string;
    /**
     * agreement text, may include html links
     */
    info: string;
    /**
     * agreement is required to create loan
     */
    isRequired: boolean;
    /**
     * true if agreement should be default checked
     */
    isDefaultChecked: boolean;
    /**
     * Cсылка на документ
     */
    document?: string;
    /**
     * Дополнительные документы соглашений.
     */
    subAgreements?: Agreement[];
    /**
     * Тип соглашения для сортировки по блокам.
     */
    blockType: BlockType;
  };

  type Response = Array<Agreement>;
}

class LoanApi extends Api {
  createShortLoan = (period, amount, offset, ga, utm, referrer, utm2, referrer2) =>
    this.post('loan.createShortLoan', {
      period,
      amount,
      offset,
      ga,
      utm,
      utm2,
      referrer,
      referrer2,
    });

  createLoan = (period, amount, offset, ga, utm, referrer, utm2, referrer2) =>
    this.post('loan.create', {
      period,
      amount,
      offset,
      ga,
      utm,
      utm2,
      referrer,
      referrer2,
    });

  createSelectedLoan = ({
    period,
    amount,
    // promoCode,
    tariffId,
    ga,
    utm,
    referrer,
    fpsUserFp,
    fpsSessionId,
    utm2,
    referrer2,
  }) =>
    this.post('loan.createSelectedLoan', {
      period,
      amount,
      // promoCode,
      tariffId,
      ga,
      utm,
      utm2,
      referrer,
      referrer2,
      fpsUserFp,
      fpsSessionId,
    });

  createBySelectedLoan = (
    data: CreateBySelectedLoan.Request,
  ): Promise<CreateBySelectedLoan.Response> => this.post('loan.createBySelectedLoan', data);

  sendConfirmationSms = (): Promise<SendConfirmationSms.Response> =>
    this.post('loan.sendConfirmationSms', {}, true, true);

  sendConfirmationCode = (): Promise<SendConfirmationSms.Response> => {
    return this.post('loan.sendConfirmationCode', {}, true, true);
  };

  /**
   * Возвращает сохраненные параметры калькулятора с backend.
   * Если данных нет, возвращает ошибку. Данные стираются при создании займа.
   */
  getSelectedLoan = () => {
    return this.post('loan.getSelectedLoan', {});
  };

  setFirstPaymentDate = (date: string): Promise<true> =>
    this.post('loan.setFirstPaymentDate', { date });

  /**
   * Метод возвращает общие условия займа на страницах подписания договора.
   */
  getLoanOrder = (): Promise<GetLoanOrder.Response> => this.post('loan.getLoanOrder');

  sign = (confirmationCode: string): Promise<Sign.Response> =>
    this.post('loan.sign', { confirmationCode });

  transferToKorona() {
    return this.post('loan.transferToKoronaPay');
  }

  transferToContact() {
    return this.post('loan.transferToContact');
  }

  transferToDzp() {
    return this.post('loan.transferToDzp');
  }

  payoutViaCard = (creditCardId) => {
    return this.post('loan.payout', { creditCardId, source: 'ver3' });
  };

  payoutViaKorona = () => {
    return this.post('loan.payoutToKoronaCard');
  };

  deleteLoan = () => {
    return this.post('loan.deleteLoan');
  };

  startRecalculation = () => {
    return this.post('loan.startRecalculation');
  };

  getRecalculationAmount = () => {
    return this.post('loan.getRecalculationAmount');
  };

  getDocuments = (id) => {
    return this.post('loan.getDocuments', { id });
  };

  setDocument = (document) => {
    return this.post('document.addRecalculationDocument', { document });
  };

  /**
   * Метод используемый для перехода с КД на Смарт.
   */
  downgrade = () => {
    return this.post('loan.downgrade');
  };

  getAgreements = (): Promise<GetAgreements.Response> => {
    return this.post('loan.getAgreements');
  };

  /**
   * Метод возвращает соглашения для футера.
   */
  getLinkAgreements = (): Promise<GetAgreements.Response> => {
    return this.post('loan.getLinkAgreements');
  };

  createOrUpdateDraft = (id, loanApplicationId: '9961602c-2214-44d6-ae0a-59d539d70081', fields) => {
    return this.post(
      'loanApplicationDraft.createOrUpdate',
      { id, loanApplicationId, fields },
      true,
      true,
    );
  };
}

// eslint-disable-next-line import/no-default-export
export default new LoanApi();
