import React, { useEffect, FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CaptchaV3 from 'services/captchaV3';

export const SetReCaptcha: FC = ({ children }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    CaptchaV3.setExecuteRecaptcha(executeRecaptcha);
  }, [executeRecaptcha]);

  return <>{children}</>;
};
