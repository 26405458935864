/*
 * DO NOT REMOVE THIS FILE IF ALL TESTS ARE FINISHED, ONLY CLEAN 'ABTest' OBJECT;
 * PLEASE, WRITE SHORT DESCRIPTION FOR ALL ACTIVE TESTS WITH EXPLANATION OF ALL GROUP VALUES;
 */

/**
 * Варианты групп.
 */
export enum ABGroup {
  a = 0,
  b = 1,
  c = 2,
  d = 3,
}

/**
 * Нужно ипользовать константу ABTestNameClickhouse для обоих модулей метрики DWH.
 * if you need to use some marketing metrics for ABTest, please use "services/marketing/events/abTest" folder
 * For example: ABTest {
 *   DWN_6876 = 'DWN_6876',
 * }
 */
export enum ABTest {
  /**
   * АБ тест работает в рамках задачи https://jira.devim.team/browse/DWN-11129
   */
  DWN_11129 = 'DWN_11129',
}

/**
 * Название активного теста для отправки в кликхаус.
 * Для активации нужно указать название теста с типом string (пр: 'DWN_10000')
 * Если нужно деактивировать тест, то нужно указать значение `''`.
 * @todo изменить на пустую строку после теста и использовать в старой метрике.
 */
export const ABTestNameClickhouse = '';

/*
 * DWN-11074 Настройка абтеста
 *
 * a - 10%, видят экспериментальный экран, по ним замеряем "эффективно или нет".
 * b - 10%, видят стандартный экран, по ним сравниваем метрики тестовой группы и подтверждать эффективность.
 * с - 80%, видят стандартный экран.
 */

/**
 * ABTestVariation - the set of maximal variations for test.
 * For example:
 * [ABTest.DWN_5413]: {
    [ABGroup.a]: 8,
    [ABGroup.b]: 1,
    [ABGroup.c]: 1,
  },
  * result is a:b:c = 80:10:10
 */
export const ABTestVariation: Record<ABTest, Partial<Record<ABGroup, number>>> = {
  [ABTest.DWN_11129]: {
    [ABGroup.a]: 60,
    [ABGroup.b]: 30,
    [ABGroup.c]: 10,
  },
};

/**
 * Название группы пользователя.
 */
export enum UserGroup {
  Test = 'test',
  Control = 'control',
  Basic = 'basic',
}
