import React from 'react';

import moment, { MomentInput } from 'moment';
import * as loan from 'const/loan';
import { GENDER } from 'const/loan';
import * as dadata from 'const/dadata';
import { Service as ClickhouseMetricService } from '../modules/common/ClickhouseMetric';
import Cookie from './Cookie';

// eslint-disable-next-line import/no-default-export
export default class Helpers {
  static asDate(date: MomentInput, format = 'DD.MM.YYYY') {
    const parsed = moment.parseZone(date);
    return parsed.isValid() ? parsed.format(format) : '-';
  }

  static asPercent(value, options = {}): string {
    const defaultOptions = {
      withSymbol: true,
      withFloat: true,
      digits: 2,
    };

    const opts = { ...defaultOptions, ...options };

    const plainText = String(value)
      .replace(',', '.')
      .replace(/[^0-9.]/g, '');
    let result = Number(plainText).toFixed(opts.withFloat ? opts.digits : 0);

    if (opts.withSymbol) {
      result += '%';
    }

    return result;
  }

  static withQuotes(text) {
    return text ? `«${text}»` : text;
  }

  static onlyNumbers(value) {
    return String(value).replace(/[^0-9]/g, '');
  }

  static getLoanEndDate({ startDate = moment(), days = 0, weeks = 0, months = 0 }) {
    return moment(startDate)
      .startOf('day')
      .add(days, 'days')
      .add(weeks, 'weeks')
      .add(months, 'months')
      .subtract(loan.LOAN_DIFF_DAYS, 'days');
  }

  static asMoney(value: number, { showCurrencySymbol = true, showKopecks = true } = {}) {
    const fixedValue = showKopecks ? 2 : 0;

    return `${Number(value / 100).toLocaleString('ru', {
      minimumFractionDigits: fixedValue,
      maximumFractionDigits: fixedValue,
    })} ${showCurrencySymbol ? '₽' : ''}`;
  }

  static formatAsMoney(value) {
    return String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  static asKb(size) {
    return `${((size || 0) / 1024).toFixed(2)}`;
  }

  static asMobilePhone(number) {
    return String(number).replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
  }

  static plural(value: number, one: string, two: string, five: string = two) {
    let number = Math.abs(value);
    number %= 100;

    if (number >= 5 && number <= 20) {
      return five;
    }

    number %= 10;

    if (number === 1) {
      return one;
    }

    if (number >= 2 && number <= 4) {
      return two;
    }

    return five;
  }

  static pluralDays(days) {
    return this.plural(days, 'день', 'дня', 'дней');
  }

  static pluralDaysDeclension(days) {
    return this.plural(days, 'дня', 'дней', 'дней');
  }

  static pluralYearsDeclension(years) {
    return this.plural(years, 'года', 'лет', 'лет');
  }

  static pluralWeeks(weeks) {
    return this.plural(weeks, 'неделя', 'недели', 'недель');
  }

  static pluralMonths(months) {
    return this.plural(months, 'месяц', 'месяца', 'месяцев');
  }

  static pluralPayments(count) {
    return this.plural(count, 'платеж', 'платежа', 'платежей');
  }

  static pluralByNumber(number) {
    return this.plural(number, 'номеру', 'номерам', 'номерам');
  }

  static pluralBonuses(number) {
    return this.plural(number, 'балл', 'балла', 'баллов');
  }

  static pluralLoans(number) {
    return this.plural(number, 'займ', 'займа', 'займов');
  }

  static splitText(value) {
    return value.split('\n').map((item, key) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });
  }

  static arrayFill(length, value) {
    const numbers = Array(length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numbers.length; i++) {
      numbers[i] = value;
    }
    return numbers;
  }

  static calculateValueRange(min = 0, max = 10, step = 1, includeMax = true) {
    let iterator = min;
    const values = [];

    while (iterator <= max) {
      values.push(iterator);
      iterator += step;
    }

    if (includeMax) {
      if (values.indexOf(max) === -1) {
        values.push(max);
      }
    }

    return values;
  }

  static handleMinMaxLimits(value, min, max) {
    return Math.min(Math.max(value, min), max);
  }

  static convertSizeMbToBytes(sizeMb) {
    return sizeMb * 1024 * 1000;
  }
}

export function calcHHMM(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
}

export function transformGenderToDadata(gender: GENDER) {
  switch (gender) {
    case GENDER.UNKNOWN:
      return dadata.GENDER_UNKNOWN;
    case GENDER.MALE:
      return dadata.GENDER_MALE;
    case GENDER.FEMALE:
      return dadata.GENDER_FEMALE;
    default:
      return dadata.GENDER_UNKNOWN;
  }
}

export function transformGenderFromDadata(gender) {
  switch (gender) {
    case dadata.GENDER_UNKNOWN:
      return GENDER.UNKNOWN;
    case dadata.GENDER_MALE:
      return GENDER.MALE;
    case dadata.GENDER_FEMALE:
      return GENDER.FEMALE;
    default:
      return GENDER.UNKNOWN;
  }
}

export function hashCode(str: string) {
  return Array.from(str).reduce(
    // eslint-disable-next-line no-bitwise
    (result, current) => (Math.imul(31, result) + current.charCodeAt(0)) | 0,
    0,
  );
}

/**
 * Приводит строку к camelCase
 */
export function toCamelCase(str: string): string {
  const result = str.replace(/[^A-Za-z0-9]*([A-Za-z0-9]+)*/g, (match, item) =>
    item ? item[0].toUpperCase() + item.substr(1) : '',
  );

  return result ? result[0].toLowerCase() + result.substr(1) : result;
}

/**
 * Функциональная композиция
 */
export const compose = (...functions) => (args) =>
  functions.reduceRight((arg, fn) => fn(arg), args);

/**
 * Удаление пробелов с начала и конца строки
 */
export function trim(value: string) {
  return String.prototype.trim.call(value);
}

/**
 * Каждое слово в строке - с заглавной буквы
 */
export function capitalize(value: string) {
  return value.replace(/(^|[-\s])[\wа-яё]/g, (str) => str.toUpperCase());
}

export function handleTakeLoanStep3ErrorMetric(textErrorDescription, errorCode) {
  const resultDescription =
    typeof textErrorDescription === 'string' ? textErrorDescription : textErrorDescription.message;
  ClickhouseMetricService.push('takeLoanStep3Error', `${errorCode}`);
  ClickhouseMetricService.push('takeLoanStep3ErrorDescription', `${resultDescription}`);
  if (
    textErrorDescription.message &&
    textErrorDescription.message.includes('Мы заметили техническую')
  ) {
    const failedMetricsCookie = JSON.parse(Cookie.get('_d_failed_metrics')) || [];
    const failedMetric = {
      title: 'takeLoanStep3ErrorDescription',
      value: `Internet failure: ${resultDescription}`,
    };
    failedMetricsCookie.push(failedMetric);
    Cookie.set('_d_failed_metrics', JSON.stringify(failedMetricsCookie));
  }
}

export function changeLatToCyr(value: string) {
  const map = {
    q: 'й',
    w: 'ц',
    e: 'у',
    r: 'к',
    t: 'е',
    y: 'н',
    u: 'г',
    i: 'ш',
    o: 'щ',
    p: 'з',
    '[': 'х',
    ']': 'ъ',
    a: 'ф',
    s: 'ы',
    d: 'в',
    f: 'а',
    g: 'п',
    h: 'р',
    j: 'о',
    k: 'л',
    l: 'д',
    ';': 'ж',
    "'": 'э',
    z: 'я',
    x: 'ч',
    c: 'с',
    v: 'м',
    b: 'и',
    n: 'т',
    m: 'ь',
    ',': 'б',
    '.': 'ю',
    Q: 'Й',
    W: 'Ц',
    E: 'У',
    R: 'К',
    T: 'Е',
    Y: 'Н',
    U: 'Г',
    I: 'Ш',
    O: 'Щ',
    P: 'З',
    A: 'Ф',
    S: 'Ы',
    D: 'В',
    F: 'А',
    G: 'П',
    H: 'Р',
    J: 'О',
    K: 'Л',
    L: 'Д',
    Z: '?',
    X: 'ч',
    C: 'С',
    V: 'М',
    B: 'И',
    N: 'Т',
    M: 'Ь',
    '<': 'Б',
    '>': 'Ю',
    '{': 'Х',
    '}': 'Ъ',
    ':': 'Ж',
    '@': 'Э',
    '"': 'Э',
    '`': 'ё',
    '~': 'Ё',
  };

  const res = value.split('');
  for (let i = 0; i < res.length; i += 1) {
    if (map[res[i]]) res[i] = map[res[i]];
  }

  return res.join('');
}

/**
 * Переводит ключевые слова в тексте в творительный падеж.
 * @param text Текст.
 */
export const transformText = (text: string) =>
  text
    .replace(/рафик\s/im, 'рафиком ')
    .replace(/ский\s/, 'ским ')
    .replace(/оговор\s/, 'оговором ')
    .replace(/нкета(\s|-)/, 'нкетой$1')
    .replace(/явление/, 'явлением')
    .replace(/щие/, 'щими')
    .replace(/вия/, 'виями');
