import { observable, computed, action, runInAction } from 'mobx';
import moment from 'moment';

import * as companies from 'const/companies';
import CurrentLoanModel from 'models/CurrentLoan';
import * as ClientApi from 'services/backend/Client';
import NotificationStore from 'store/NotificationStore';
import { LOAN_STATUS, LOAN_TYPE, DOCUMENT_TYPE } from 'const/loan';

class CurrentLoanStore {
  @observable
  currentLoan = new CurrentLoanModel();

  @observable
  isDataLoaded = false;

  private lastLoanStatus: LOAN_STATUS;

  @computed
  get loanStatus(): LOAN_STATUS {
    return this.currentLoan.status;
  }

  @computed
  get hasTransfers() {
    const koronaTransfers = this.getValue('koronaTransfers').get();
    const contactTransfers = this.getValue('contactTransfers').get();
    const dzpTransfers = this.getValue('dzpTransfers').get();

    return (
      koronaTransfers.length !== 0 || contactTransfers.length !== 0 || dzpTransfers.length !== 0
    );
  }

  @computed
  get transfers() {
    const koronaTransfers = this.getValue('koronaTransfers').get();
    const contactTransfers = this.getValue('contactTransfers').get();
    const dzpTransfers = this.getValue('dzpTransfers').get();

    if (koronaTransfers.length !== 0) return koronaTransfers;
    if (contactTransfers.length !== 0) return contactTransfers;
    if (dzpTransfers.length !== 0) return [];

    return [];
  }

  @computed
  get transferredToCompany() {
    const koronaTransfers = this.getValue('koronaTransfers').get();
    const contactTransfers = this.getValue('contactTransfers').get();
    const dzpTransfers = this.getValue('dzpTransfers').get();

    if (koronaTransfers.length !== 0) return companies.KORONA;
    if (contactTransfers.length !== 0) return companies.CONTACT;
    if (dzpTransfers.length !== 0) return companies.DZP;

    return '';
  }

  @computed
  get transferredToCompanyName() {
    const koronaTransfers = this.getValue('koronaTransfers').get();
    const contactTransfers = this.getValue('contactTransfers').get();
    const dzpTransfers = this.getValue('dzpTransfers').get();

    if (koronaTransfers.length !== 0) return companies.listTo[companies.KORONA];
    if (contactTransfers.length !== 0) return companies.listTo[companies.CONTACT];
    if (dzpTransfers.length !== 0) return companies.listTo[companies.DZP];

    return '';
  }

  /**
   * Возвращает сумму, необходимую для погашения займа с учётом перерасчета.
   */
  @computed
  get repaymentAmount() {
    const type = this.getValue('type').get();
    const recalculateAmount = this.getValue('recalculateAmount').get() || 0;
    const amount = this.getValue('amount').get() || 0;

    const isRecalculated = type === LOAN_TYPE.FIRST_180 && recalculateAmount > 0;
    return isRecalculated ? recalculateAmount : amount;
  }

  @computed
  get forbiddenTill() {
    return new Date(this.currentLoan.forbiddenTill);
  }

  @computed
  get discounts() {
    return this.currentLoan.discounts;
  }

  @computed
  get rejectedDaysLeft() {
    return moment(this.forbiddenTill).startOf('day').diff(moment().startOf('day'), 'days');
  }

  @action
  getValue<K extends keyof CurrentLoanModel>(param: K) {
    return computed(() => {
      return this.currentLoan.getValue(param);
    });
  }

  @action
  setValue<K extends keyof CurrentLoanModel>(param: K, value: CurrentLoanModel[K]) {
    return this.currentLoan.setValue(param, value);
  }

  @action
  fetch = (forceUpdate = false) => {
    return ClientApi.getDashboardInfo(forceUpdate).then((response) => {
      runInAction(() => {
        this.currentLoan = new CurrentLoanModel(response);

        this.checkChanges();

        // Crutch for period
        if (this.currentLoan.period) {
          this.currentLoan.period -= 1;
        }

        this.isDataLoaded = true;
      });
    });
  };

  checkChanges() {
    const loanStatus = this.currentLoan.status;
    if (
      this.lastLoanStatus === LOAN_STATUS.UNDERWRITING &&
      loanStatus !== LOAN_STATUS.UNDERWRITING
    ) {
      NotificationStore.changePaymentEventState(true);
    }
    this.lastLoanStatus = loanStatus;
  }

  @computed
  get isPreOrders() {
    if (!this.isDataLoaded) {
      return false;
    }

    const preOrders = this.getValue('preOrders').get();
    const status = this.getValue('status').get();

    const isStatus = status === LOAN_STATUS.APPROVED || status === LOAN_STATUS.APPROVED_CHANGED;
    return isStatus && preOrders && preOrders.length > 2;
  }

  @computed
  get loanName() {
    if (!this.isDataLoaded) {
      return 'false';
    }
    return this.currentLoan.name;
  }

  @computed
  get isRestructureAllowed() {
    if (!this.isDataLoaded) {
      return 'false';
    }
    return this.currentLoan.isRestructureAllowed;
  }

  @computed
  get loanApproveDate() {
    if (!this.isDataLoaded) {
      return '';
    }
    return this.currentLoan.approveDate;
  }

  /**
   * Указывает, что данный PDL-займ был реструктурирован так, что у него
   * появился график платежей.
   *
   * Этот адский гибрид описан в Confluence ДЗП.
   *
   * @todo Чтобы определить этот юридический казус, нужен способ получше.
   * К примеру, отдельный флаг в API. А ещё лучше - дополнительный статус.
   */
  @computed
  get isRestructuredPdl() {
    if (!this.isDataLoaded) {
      return false;
    }

    const documents = this.getValue('documentList').get();
    const type = this.getValue('type').get();

    return (
      type === LOAN_TYPE.SHORT &&
      documents.some((document) => document.type === DOCUMENT_TYPE.SCHEDULE)
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default new CurrentLoanStore();
