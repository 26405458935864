import { CreditHolidaysType } from 'const/creditHolidays';
import Model from './Model';

// eslint-disable-next-line import/no-default-export
export default class ClientModel extends Model<ClientModel> {
  public id = 0;

  public photo = '';

  public lastName = '';

  public firstName = '';

  public patronymic = '';

  public mobilePhone = '';

  public birthDate = '';

  public email = '';

  public bonuses = 0;

  public verified = false;

  public creditRating = 0;

  // Первичник, который вообще никогда не подавал заявок
  public isNew = true;

  // идентификация первичника в процессе
  public onVerifying = false;

  // Клиент, имеющий какой-то статус по заявкам
  public haveApplications = false;

  public haveKoronaCard = false;

  public socials = [];

  public toFreeLoan = 0;

  public toFreeProlongation = 0;

  public identificationCode = '';

  public showEmailWarning = false;

  public mailAgreement = false;

  public showWelcomePopUp = false;

  public esiaLock = false;

  public isCachedResult = false;

  public isCacheEnabled = false;

  public shortLoan = false;

  public nextLoanFreeDays = 0;

  public interestRate = 0;

  public promoPoints = 0;

  public availablePromos = {};

  /* активированы ли кредитные каникулы */
  public isCreditHolidaysEnabled = false;

  /* ссылка на соглашение о кредитных каникулах */
  public creditHolidaysNotificationDocumentUrl = '';

  /* тип кредитных каникул */
  public creditHolidaysType: CreditHolidaysType = CreditHolidaysType.Covid;

  public constructor(data?: Partial<ClientModel>) {
    super();
    this.init(data);
  }
}
