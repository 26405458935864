import React from 'react';
import { render } from 'react-dom';
// https://jira.devim.team/browse/DWN-12373 временное отключение метрик из SENTRY
// import { ErrorBoundary } from '@sentry/react';

import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { history, routeList } from 'routes';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import * as urls from 'const/urls';

import 'assets/css/normalize.css';
import 'assets/css/fonts.css';
import 'assets/css/common.css';
import { NotificationManager } from 'modules/NotificationManager';
import { Toasts } from 'modules/Toasts';

import Cookie from 'utils/Cookie';
import Url from 'utils/Url';
import { UTM } from 'utils/UTM';
import Marketing from 'services/Marketing';

import CombinedCalculatorStore from 'store/CombinedCalculator';

import * as cookies from 'const/cookies';
import * as queryStrings from 'const/queryStrings';
import { SecuredLayout } from 'modules/SecuredLayout';
import { LocationStore } from 'store/LocationStore';

// import {
//   // SENTRY_DSN,
//   // SENTRY_ENVIRONMENT,
//   // SENTRY_PERF_TRACES_RATE,
// } from 'const/keys';
import { ChatApi } from 'services/Chat';
import { ChatShowedEvent } from 'services/marketing/events/ChatShowedEvent';
import { ChatMessageEvent } from 'services/marketing/events/ChatMessageEvent';
import { Service as ClickhouseService } from 'modules/common/ClickhouseMetric';
import { Service as MyTargetService } from 'modules/common/MyTargetMetric';
import { Service as GoogleService } from 'modules/common/GoogleMetric';
import { MetricPageEnter, Service as MetricService } from 'modules/common/MetricService';
import * as env from 'const/env';

import { SetReCaptcha } from 'SetReCaptcha';
import { version } from '../package.json';

// https://jira.devim.team/browse/DWN-12373 временное отключение метрик из SENTRY
// import { Service as SentryService, SetContext as SentrySetContext } from 'modules/common/Sentry';

// import { ApplicationSmartBanner } from 'modules/ApplicationSmartBanner';

// Инициализируем сервисы метрик.
ClickhouseService.initialize(env.CLICKHOUSE_URL, env.CLICKHOUSE_ID, env.CLICKHOUSE_CLIENT_ID);
GoogleService.initialize(env.GOOGLE_GTM, env.GOOGLE_GTAG);
MyTargetService.initialize(env.MYTARGET_ID);

// https://jira.devim.team/browse/DWN-12373 временное отключение метрик из SENTRY
// SentryService.initialize({
//   dsn: SENTRY_DSN,
//   environment: SENTRY_ENVIRONMENT,
//   history,
//   tracesSampleRate: SENTRY_PERF_TRACES_RATE,
// });

const isLocalDevelopment =
  typeof window !== 'undefined' && window.location.hostname === '127.0.0.1';

MetricService.initialize({
  url: env.CLICKHOUSE_URL_V2,
  key: env.CLICKHOUSE_ID,
  clientId: env.CLICKHOUSE_CLIENT_ID,
  utmCookieKey: cookies.UTM,
  prodVersion: version,
  dry: isLocalDevelopment,
});

render(
  <>
    <GoogleReCaptchaProvider reCaptchaKey={env.RECAPTCHA_KEY} useEnterprise>
      <SetReCaptcha />
      {/* Баннер временно отключен DWN-7264 */}
      {/*<ApplicationSmartBanner />*/}
      <Router history={history}>
        <>
          <Route
            render={() => {
              // Save UTM
              UTM.save();
              UTM.saveNew();

              // Запускает listener на изменение url
              LocationStore.instance.listen();

              // Marketing metrics
              Marketing.pushUid(Cookie.get(cookies.USER_TOKEN));

              window.scrollTo(0, 0);

              // parse calculator values
              if (typeof window !== 'undefined') {
                const queryParams = Url.getQueryParameters(window.location.search);

                if (
                  queryParams[queryStrings.CALC_AMOUNT] &&
                  queryParams[queryStrings.CALC_PERIOD] &&
                  queryParams[queryStrings.CALC_TARIFF] &&
                  queryParams[queryStrings.CALC_TOTAL_DAYS]
                ) {
                  CombinedCalculatorStore.saveValuesToCookies({
                    tariffId: queryParams[queryStrings.CALC_TARIFF],
                    amount: queryParams[queryStrings.CALC_AMOUNT],
                    period: queryParams[queryStrings.CALC_PERIOD],
                    days: queryParams[queryStrings.CALC_TOTAL_DAYS],
                    // promoCode: queryParams[queryStrings.CALC_PROMO_CODE],
                  });
                }

                const { fromApp } = queryParams;

                if (!fromApp) {
                  // Initialize the chat events.
                  ChatApi.getInstance().on('start', () => new ChatShowedEvent().dispatch());
                  ChatApi.getInstance().on('send', () => new ChatMessageEvent().dispatch());
                }
              }

              return null;
            }}
          />
          <Switch>
            {Object.keys(routeList.modules).map((module) => (
              <Route {...routeList.modules[module]} key={module} />
            ))}
            <SecuredLayout>
              <Switch>
                {Object.keys(routeList.modulesWithMenu).map((module) => (
                  <Route {...routeList.modulesWithMenu[module]} key={module} />
                ))}
                <Redirect to={urls.NOT_FOUND} />
              </Switch>
            </SecuredLayout>
          </Switch>
          <MetricPageEnter />
        </>
      </Router>
      {/* https://jira.devim.team/browse/DWN-12373 временное отключение метрик из SENTRY */}
      {/* <SentrySetContext /> */}
      <NotificationManager />
      <Toasts />
    </GoogleReCaptchaProvider>
  </>,
  document.getElementById('app'),
);
