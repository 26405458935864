import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import YandexMetricProvider from 'services/marketing/providers/YandexMetricProvider';
import { LoaderLogo, LoaderWrapper, Wrap } from 'modules/Layout/styled';
import { LeftPanel } from 'modules/LeftPanel';
import { Header } from 'modules/Header';
import { Footer } from 'modules/Footer';
import { ErrorBoundary } from 'modules/ErrorBoundary';
import { Service as MetricService } from 'modules/common/MetricService';

import PushApi from 'services/Push';
import ClientStore from 'store/Client';
import ToastsStore from 'store/Toasts';
import AppStore from 'store/App';
import CurrentLoanStore from 'store/CurrentLoan';
import RouteManager from 'routes/manager';
import Firebase from 'utils/Firebase';
import { BackendError } from 'services/backend/BackendError';

import { CircleLoading } from 'ui/CircleLoading';
import Logo from 'ui/svg-icons/Logos/Large';

import * as toasts from 'const/toasts';
import * as urls from 'const/urls';
import * as rpc from 'const/rpc';
import {
  LOAN_STATUS,
  TYPE_FIRST_180_ADDITIONAL,
  TYPE_REPEAT_180,
  FULL_LOAN_TYPE,
} from 'const/loan';
import Url from 'utils/Url';
import { history } from '../../routes';

@observer
class SecuredLayout extends Component<RouteComponentProps> {
  /* eslint-disable react/sort-comp */
  private clientStore = ClientStore;

  private currentLoanStore = CurrentLoanStore;

  public async componentDidMount() {
    const { location } = this.props;
    this.checkExternalAuth();
    const { isLoggedIn } = this.clientStore;

    if (!isLoggedIn) {
      const redirectUrl =
        // eslint-disable-next-line no-nested-ternary
        location.pathname === urls.TAKE_LOAN
          ? urls.LOGIN_TAKE_LOAN
          : location.pathname === urls.LEGAL_SUPPORT
          ? urls.LOGIN_TAKE_SERVICE
          : urls.LOGIN;

      RouteManager.setLoginRedirect(location.pathname + location.search);
      RouteManager.goTo({ url: redirectUrl, replace: true });

      return;
    }

    try {
      await this.clientStore.touch();

      const { clientType } = this.clientStore;

      YandexMetricProvider.sendMetric(`clientType_${clientType}`);
    } catch (error) {
      const isUnauthorizedError =
        error instanceof BackendError && error.code === rpc.UNAUTHORIZED_ERROR;
      const text = isUnauthorizedError
        ? 'Время сессии истекло. Пожалуйста, повторите вход в Личный кабинет заново.'
        : 'Ошибка при получении данных пользователя';

      if (isUnauthorizedError) {
        MetricService.onSessionEnded('expired');
      }

      this.clientStore.deleteAuthCredentials();
      RouteManager.goToLogin();

      ToastsStore.add({
        text,
        type: toasts.TYPE_ERROR,
        timeout: 7000,
      });
    }

    this.clientStore.fetchCreditCards();
    await this.currentLoanStore.fetch();

    const product = this.getProductMetric();

    if (product != null) {
      YandexMetricProvider.sendMetric(`product_${product}`);
    }

    const redirect = RouteManager.getLoginRedirect();

    if (redirect) {
      RouteManager.setLoginRedirect(undefined);
      RouteManager.goTo({ url: redirect, replace: true });

      return;
    }

    // todo лучше эту проверку вытащить в AppStore, через observe
    if (this.currentLoanStore.getValue('status').get() === LOAN_STATUS.NO_LOAN) {
      AppStore.startPingTimer();
    }

    this.initFirebase();
  }

  private getProductMetric() {
    const loanType = this.currentLoanStore.getValue('loanType').get();

    switch (loanType) {
      case TYPE_FIRST_180_ADDITIONAL: {
        return 'kd';
      }
      case TYPE_REPEAT_180: {
        return 'kd2';
      }
      case FULL_LOAN_TYPE.SMART: {
        return 'smart';
      }
      case FULL_LOAN_TYPE.MINI: {
        return 'mini';
      }
      default: {
        return undefined;
      }
    }
  }

  // todo move it in separate file
  // eslint-disable-next-line react/sort-comp
  private async initFirebase() {
    try {
      const isGranted = await Firebase.requestPermissions();

      if (isGranted) {
        Firebase.onMessageCallBack = (payload) => {
          // eslint-disable-next-line no-console
          console.log('Message received. ', payload);
        };
        Firebase.onTokenRefreshCallBack = (e) => {
          // eslint-disable-next-line no-console
          console.log('Token refreshed ', e);
        };

        const token = await Firebase.getToken();

        if (token) {
          PushApi.register(token);
        }
      }
    } catch (e) {
      // TODO: handling error
    }
  }

  private checkExternalAuth() {
    const { location } = this.props;
    const { token } = Url.getQueryParameters(location.search);

    if (token) {
      ClientStore.authorize(token as string);
      history.replace(location.pathname);
    }
  }

  public render() {
    const { children } = this.props;

    let showFooter = true;

    if (typeof window !== 'undefined') {
      showFooter = !/^\/take-loan/.test(window.location.pathname);
    }

    const {
      hasSuccess,
      clientType,
      currentUser: { isCreditHolidaysEnabled },
    } = this.clientStore;

    if (!hasSuccess) {
      return (
        <LoaderWrapper>
          <LoaderLogo>
            <Logo />
          </LoaderLogo>
          <CircleLoading />
        </LoaderWrapper>
      );
    }

    const status = this.currentLoanStore.getValue('status').get();
    const isFirstWithAcceptedLoan = clientType === 'first' && status === LOAN_STATUS.APPROVED;

    return isCreditHolidaysEnabled ? (
      <Redirect to={urls.CREDIT_HOLIDAYS_ACTIVE} />
    ) : (
      <Wrap>
        <LeftPanel />
        <Header />
        {isFirstWithAcceptedLoan && <Redirect to={urls.TAKE_LOAN_SIGN_CONTRACT} />}
        <ErrorBoundary>{children}</ErrorBoundary>

        {showFooter && (
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        )}
      </Wrap>
    );
  }
}

const component = withRouter(SecuredLayout);

export { component as SecuredLayout };
