import { styled } from 'const/stitches';

export const Button = styled('button', {
  backgroundColor: '$color-main',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$color-main',
  borderRadius: '4px',
  transition: '.15s ease-out',
  padding: '.9em 1em',
  outline: 'none',
  fontSize: '14px',
  minWidth: '174px',

  '&:hover:not([disabled])': {
    backgroundColor: '$color-main-hover',
    borderColor: '$color-main-hover',
  },

  '&[disabled]': {
    color: 'rgba(0,0,0,0.3)',
    background: '$color-main-disabled',
    borderColor: '$color-main-disabled',
    cursor: 'default',
  },

  '@mediaMobileAndLess': {
    padding: '.9em 0',
    minWidth: '100%',
  },

  defaultVariants: {
    uppercase: 'true',
  },

  variants: {
    uppercase: {
      true: {
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontWeight: 600,
      },
    },
    secondary: {
      true: {
        backgroundColor: '$color-secondary',
        borderColor: '$color-secondary',
        color: '#fff',
        '&:hover:not([disabled])': {
          backgroundColor: '$color-secondary-hover',
          borderColor: '$color-secondary-hover',
        },
      },
    },
    noFill: {
      true: {
        background: '#fff',
        color: '$color-text-primary',
        transition: 'color .15s',
        '&:hover:not([disabled])': {
          background: '#fff',
          color: '$color-main',
        },
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    blue: {
      true: {
        backgroundColor: '#8FA7FE',
        borderColor: '#8FA7FE',

        '&:hover:not([disabled])': {
          backgroundColor: '#C6D2FF',
          borderColor: '#C6D2FF',
        },
      },
    },
    icon: {
      true: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
      },
    },
  },

  compoundVariants: [
    {
      secondary: true,
      noFill: true,
      css: {
        '&:hover:not([disabled])': {
          color: '$color-secondary',
        },
      },
    },
  ],
});

export const Icon = styled('span', {
  height: '12px',
});

export const Text = styled('span', {});
