import moment from 'moment';

import {
  APPROVED_TYPE,
  DOCUMENT_TYPE,
  LOAN_STATUS,
  LOAN_TYPE,
  TYPE_REPEAT_180,
  LOAN_SOURCE,
} from 'const/loan';
import Model from './Model';

interface Document {
  type: DOCUMENT_TYPE;
  url: string;
  createdat: string;
}

export interface Preorder {
  id: number;
  amount: number;
  percent: number;
  freePeriod: number;
  period: number;
  freePeriodAmount?: number;
  firstPayment?: number;
  servicePrice: number;
  isChosenByDefault?: boolean;
  position: number;
  isExistLegalService: boolean;
  insetId: number;
}

// eslint-disable-next-line import/no-default-export
export default class CurrentLoanModel extends Model<CurrentLoanModel> {
  // ID займа
  public id = 0;

  // тип займа (pdl, install)
  public type: LOAN_TYPE = 0;

  // название займа
  public name = '';

  // статус займа
  public status: LOAN_STATUS = -1;

  /**
   * Источник появления займа. Описание всех возможных источников появления
   * займа можно найти в описании enum LOAN_SOURCE.
   */
  public source: LOAN_SOURCE = LOAN_SOURCE.NONE;

  // сумма займа
  public amount = 0;

  // Размер тела займа.
  public amountLeft = 0;

  // срок займа в днях
  public period = 0;

  // период на который клиент хотел взять займ
  public periodClaimed = 0;

  // процентная ставка в день
  public interestRate = 0;

  // сумма переплаты (проценты по займу)
  public percentAmount = 0;

  // минимальный платеж если type == 1
  public minimalPayment = 0;

  // дата платежа (следующего)
  public paymentDate = '';

  // ежемесячный платеж если type == 2
  public monthlyPayment = 0;

  // количество платежей (всего) если type == 2
  public paymentsCount = 0;

  // номер договора
  public agreementNumber = '';

  // просрочен ли заём
  public isExpired = false;

  // дата начала просрочки
  public expiredDate = '';

  // общая сумма долга просрочки
  public expiredAmount = 0;

  // дата окончания андеррайтинга
  public underwritingDeadline = '';

  // список документов
  public documentList: Array<Document> = [];

  // работают ли сейчас андерры
  public isWorkingHours = false;

  // статус руна-банка
  public identificationStatus = 0;

  // вывод активированного промокода
  public bonusPromoCode = '';

  // результат одобрения
  public changedLoanTerms = APPROVED_TYPE.SAME;

  // дата выдачи займа
  public issueDate = '';

  // дата подачи заявки
  public creationDate = '';

  // дата, до которой действителен запрет по займу
  public forbiddenTill = '';

  // планируемая дата закрытия займа
  public dueToDate = '';

  // планируемая дата закрытия займа на активных займах
  public dueToDateActive = '';

  // номера переводов
  public koronaTransfers = [];

  public contactTransfers = [];

  public dzpTransfers = [];

  /**
   * Памятка для оплаты.
   */
  public requisitesDocumentUrl = '';

  /**
   * запрет получение займа на карту (из арчи).
   * На 03.06.2020 полностью дублирует значение client.getProfile -> verified
   */
  public cardPayoutAllowed = false;

  // тариф займа (mini, smart, ...)
  public loanType = 0;

  // доступность пролонгации
  public showProlongationButton = false;

  // возможность редактирова займ (удалять)
  public editable = false;

  // сумма для полного погашения займа
  public closeLoanAmount = 0;

  // сумма для полного погашения займа после перарасчета
  public recalculateAmount = 0;

  // показывать ли кнопку перерасчета займа
  public showRecalculationButton = false;

  // сумма, которую клиент хотел взять
  public claimAmount = 0;

  // есть транзакция в обработке
  public isTransactionInProgress = false;

  // текст успешно примененного промода на дешборде
  public promoCodeInfo = '';

  // Стоимость доп. услуг, входящих в продукт
  public servicePrice = 0;

  // Кол-во дней после выдачи займа
  public activeDays = 0;

  // Кол-во беспроцентных дней после выдачи займа
  public freeDays = 0;

  // Дата одобрения
  public approveDate = '';

  // Список предодобренных займов.
  public preOrders: Array<Preorder> = [];

  // True, если пользователю доступна реструктуризация займа.
  public isRestructureAllowed = false;

  // True, если у пользователя уже была или есть реструктуризация займа.
  public hasRestructuring = false;

  /*
  @deprecated
   */
  public showPayoutButton = false;

  public isGracePeriodEnds: boolean;

  public isPaymentsEnds: boolean;

  public serviceName: string;

  /** Массив объектов с доступными скидками */
  public discounts: Array<{ type: string; [key: string]: any }> = [];

  public constructor(data?: Partial<CurrentLoanModel>) {
    super();
    this.init(data);

    // True, если до конца льготного периода остался один день или меньше.
    this.isGracePeriodEnds = this.getIsGracePeriodEnds();

    // True, если график платежей окончен.
    this.isPaymentsEnds = this.getIsPaymentsEnds();

    // Название подключенной услуги.
    this.serviceName = this.getServiceName();
  }

  /**
   * Возвращает true, если до окончончания льготного периода остался один день или меньше.
   */
  private getIsGracePeriodEnds() {
    if (!this.issueDate || !this.freeDays) {
      return true;
    }

    const issueDate = moment(this.issueDate).startOf('day');
    const gracePeriodLastDate = issueDate.clone().add(this.freeDays - 2, 'days');
    const currentDate = moment().startOf('day');

    return gracePeriodLastDate.isSameOrBefore(currentDate);
  }

  /**
   * Возвращает true, если график платежей окончен.
   */
  private getIsPaymentsEnds() {
    if (!this.paymentDate) {
      return false;
    }

    const paymentDate = moment(this.paymentDate).startOf('day');
    const currentDate = moment().startOf('day');

    return paymentDate.isBefore(currentDate);
  }

  /**
   * Возвращает название подключенной услуги для данного займа.
   */
  private getServiceName() {
    return this.loanType === TYPE_REPEAT_180 ? 'Выгодный 3.0' : 'Приоритет';
  }
}
