export const UNAUTHORIZED_ERROR = -32000;
export const SERVER_ERROR_HAS_OCCURRED = -32001;
export const PASSPORT_REQUIRED_ERROR = -32009;
export const SMS_SEND_ERROR = -32010;
export const SMS_NOT_ALLOWED = -32011;
export const SMS_ALREADY_CONFIRMED = -32012;
export const SMS_MAX_SMS_EXCEED = -32013;
export const SMS_MAX_TRIES_EXCEED = -32014;
export const SMS_WRONG_CONFIRMATION = -32015;
export const SMS_CODE_ALREADY_SENT = -32016;
export const USER_NOT_FOUND = -32026;
export const WRONG_PASSWORD = -32027;
export const WRONG_USER_TYPE = -32028;
export const LOGIN_TRIES_EXCEEDED = -32029;
export const SERVER_LOGIN_ERROR = -32030;

/**
 * Займ уже существует.
 */
export const LOAN_EXISTS_ERROR = -32110;

/**
 * Клиент младше 18 лет.
 */
export const LOAN_TEEN_AGE_ERROR = -32111;

/**
 * Ошибка валидации паспорта в РФМ ФМС.
 */
export const PASSPORT_VALIDATE_RFM_ERROR = -32112;

/**
 * Ошибка капчи.
 */
export const CAPTCHA_ERROR = -36301;

export const NO_EMAIL_OR_MOBILE_PHONE = -32044;
export const METHOD_NOT_FOUND = -32601;
export const NO_SELECTED_LOAN = -32080;
export const WRONG_PROMOCODE = -32060;
export const WRONG_PROMOCODE_TEXT = -32102;
export const WRONG_PROMOCODE_DATE = -32103;
export const ESIA = -32081;
export const LOAN_REMOVE = -32082;
export const PASSPORT_UPDATE_FAILED = -32083;
export const TAKE_LOAN_TRIES_EXCEED = -32090;
export const QIWI_CARD_PAYOUT_AFTER_PASSWORD_CHANGE = -32036;

/**
 * Клиенту не доступна реструктуризация или по какой-то причине не смогли ее
 * оформить.
 * Restructuring exception. Error: Loan restructuring not allowed.
 */
export const LOAN_RESTRUCTURING_NOT_ALLOWED = -33700;

/**
 * Формат .HEIC с устройств iPhone не поддерживается.
 */
export const HEIC_ATTACHMENT_ERROR = -36009;

/**
 * Информация о не корректном БИК.
 */
export const BANK_BIK_CHECK_FAILED = -39004;

/**
 * ФИО клиента в анкете не совпадает с ФИО клиента в БД.
 */
export const RPC_CLIENT_FULLNAME_DOES_NOT_MATCH_EXCEPTION = -36010;

/**
 * Неправильный номер счета.
 */
export const RPC_INVALID_ACCOUNT_NUMBER_EXCEPTION = -36005;

/**
 * Не удалось раскодировать скан в отказе от услуги (неверный формат файла).
 */
export const RPC_INVALID_FILE = -32039;

/**
 * Техническая проблема со стороны бека, базы для поиска ИНН не отвечают.
 */
export const RPC_INVALID_INN = -39101;

/**
 * Неверные реквизиты для СБП-перевода.
 */
export const RPC_INVALID_SBP_REQUISITES = -36012;

/**
 * Не подключен СБП.
 */
export const RPC_INVALID_SBP = -36014;

/**
 * Банк не найден.
 */
export const RPC_SBP_BANK_NOT_FOUND = -36013;

/**
 * Возникла ошибка при выдаче денег. Выберите или добавьте заново карту и попробуйте снова получить деньги.
 */
export const RPC_THERE_WAS_AN_ERROR_DURING_CASH_WITHDRAWAL = -32200;

/*
 * Старые ошибки RPC.
 * Не нужно добавлять в список новые ошибки.
 */
export const RPC_UNAUTHORIZED_ERROR = -32000;
export const RPC_LOAN_CREATE_ERROR = -32003;
export const RPC_LOAN_NOT_FOUND = -32004;
export const RPC_LOAN_PAYOUT_ERROR = -32005;
export const RPC_PROLONGATION_AMOUNT_TO_PAY_ERROR = -32006;
export const RPC_BIND_CARD_ERROR = -32006;
export const RPC_CARD_NOT_FOUND = -32007;
export const RPC_NO_INN_AND_SNILS_ERROR = -32008;
export const RPC_SMS_SEND_ERROR = -32010;
export const RPC_SMS_NOT_ALLOWED = -32011;
export const RPC_SMS_ALREADY_CONFIRMED = -32012;
export const RPC_SMS_MAX_SMS_EXCEED = -32013;
export const RPC_SMS_MAX_TRIES_EXCEED = -32014;
export const RPC_SMS_WRONG_CONFIRMATION = -32015;
export const RPC_PROLONGATION_NOT_FOUND = -32020;
export const RPC_PROLONGATION_IS_NOT_CONFIRMED = -32021;
export const RPC_PROLONGATION_CREATION_FAIL = -32022;
export const RPC_LOGIN_ERROR = -32030;
export const RPC_QUICK_LOGIN_TOKEN_NOT_FOUND = -32031;
export const RPC_ACCEPTANCE_PHONE_NOT_FOUND = -32032;
export const RPC_PAYMENT_IS_TOO_BIG_ERROR = -32040;
export const RPC_PAYMENT_ERROR = -32041;
export const RPC_CREDIT_CARD_DOES_NOT_BELONG_TO_CLIENT_ERROR = -32042;
export const RPC_DEVICE_ID_NOT_FOUND_ERROR = -32043;
export const RPC_NO_EMAIL_OR_MOBILE_PHONE_ERROR = -32044;
export const RPC_UPDATE_CLIENT_ERROR = -32045;
export const RPC_LOAN_IS_ALREADY_PAID = -32046;
export const RPC_ULOGIN_ERROR = -32050;
export const RPC_PAYOUT_IS_NOT_ALLOWED = -32051;
export const RPC_PAYOUT_TINKOFF_DISALLOWED = -32034;
export const RPC_CHANGE_PHONE_SMS_ERROR = -32055;
export const RPC_WRONG_PROMO_PRIZE = -32053;
export const RPC_PAYMENT_IS_NOT_ALLOWED = -32047;
export const RPC_PASSPORT_DOUBLE_ERROR = -32048;
export const RPC_PASSPORT_NO_TIED_PHONE_ERROR = -32049;
export const RPC_PASSPORT_INVALID_ERROR = -33500;
export const RPC_SOCIAL_ERROR = -32056;
export const RPC_NO_METHOD = -32601;
export const RPC_INVALID_PARAMETERS = -32602;
export const RPC_REPEAT_LOAN_PROMO_CODE_CONDITION_ERROR = -32058;
export const RPC_NEW_LOAN_PROMO_CODE_CONDITION_ERROR = -32059;
export const RPC_INVALID_PROMO_CODE = -32060;
export const RPC_LOAN_TRANSFER_TO_CORONA_CARD_ERROR = -32063;
export const RPC_CORONA_BIND_CARD_ERROR = -32064;
export const RPC_CLIENT_DOES_NOT_HAVE_CORONA_CARD = -32065;
export const RPC_CLIENT_HAS_ACTIVE_BIND_CARD_REQUEST_ERROR = -32066;
export const RPC_KORONA_PAY_ERROR = -32068;
export const RPC_ADDITIONAL_SERVICES_BROKERAGE_NOT_FOUND = -33300;
export const RPC_ADDITIONAL_SERVICES_NOT_FOUND = -33200;
export const RPC_ADDITIONAL_SERVICES_ALREADY_PAID = -33201;
export const RPC_LOAN_DRAFT_NOT_FOUND = -34001;
export const RPC_0000 = -32067;
