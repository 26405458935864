class CaptchaV3 {
  private executeRecaptcha: (action?: string) => Promise<string> = undefined;

  private resolve: any = undefined;

  private deferred = new Promise((_resolve) => {
    this.resolve = _resolve;
  });

  public setExecuteRecaptcha(executeRecaptcha) {
    this.executeRecaptcha = executeRecaptcha;
    this.resolve();
  }

  public async getToken(action: string) {
    try {
      const token = await this.deferred.then(() =>
        this.executeRecaptcha(action.replace(/\./g, '_')),
      );

      return token;
    } catch (error) {
      throw new Error((error && error.message) || 'unknown captcha error');
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default new CaptchaV3();
