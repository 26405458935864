import React, { ReactNode } from 'react';

const parser = new DOMParser();

/**
 * Парсит строку с условиями взятия займа с бекенда.
 * Строка может включать в себя ссылку к которой нужно подключить метрику
 * открытия документа `onDocumentOpened`.
 */
export const parseStringToCheckboxLable = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick?: (e, value: string) => void;
}) => {
  if (Array.isArray(children)) {
    return children;
  }

  const template = [];
  const docHtml = parser.parseFromString(children.toString(), 'text/html');

  docHtml.body.childNodes.forEach((item: HTMLElement) => {
    if (item.nodeType === Node.TEXT_NODE) {
      template.push(<span key={item.textContent}>{item.textContent}</span>);
    }

    if (item.nodeName === 'A' && item.nodeType === Node.ELEMENT_NODE) {
      const href = item.getAttribute('href');
      const link = (
        <a
          key={item.textContent}
          onClick={(e) => onClick(e, item.textContent)}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.textContent}
        </a>
      );

      template.push(link);
    }
  });

  return template;
};
