import { Fetcher } from 'utils/Fetcher';
import parseError from 'services/parseError';
import { Service as MetricService } from 'modules/common/MetricService';

import Cookie from 'utils/Cookie';
import * as cookies from 'const/cookies';
import * as env from 'const/env';
import CaptchaV3 from './captchaV3';

// eslint-disable-next-line import/no-default-export
export default class Api {
  static instance: Api;

  static getInstance() {
    if (typeof this.instance === 'undefined') {
      this.instance = new this();
    }

    return this.instance;
  }

  private fetcher = new Fetcher();

  private ai = 1;

  private endpoint = `${env.BACKEND_HOST}`;

  constructor() {
    this.fetcher.onNetworkError((error) => {
      this.fetcher.doAfter();
      const err = { ...error };
      err.message =
        'Мы заметили техническую ошибку на нашей стороне и уже решаем её. Пожалуйста, попробуйте повторить вход в Личный кабинет позднее.';

      MetricService.onErrorShown(err.message);

      throw err;
    });

    this.fetcher.processResponse((streamResponse, request) =>
      streamResponse
        .json()
        .catch(() => {
          const message = 'Произошла серверная ошибка';

          MetricService.onErrorBackend(message);

          throw new Error(message);
        })
        .then((response) => {
          if (response.constructor === Array) {
            return response.forEach((res) => parseError(request, res));
          }
          return parseError(request, response);
        }),
    );

    this.fetcher.useDebounce((request) => {
      const debounceList = ['client.getDashboardInfo'];

      if (debounceList.includes(request.rpcMethod)) {
        return 10000; // requests from debounceList will be reused during this time
      }

      return 0;
    });
  }

  private buildHeaders(withAuthHeader = true) {
    const headers: Record<string, string> = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-DEVICE-ID': typeof navigator !== 'undefined' ? navigator.userAgent : '',
    };
    if (withAuthHeader) {
      headers.Authorization = Cookie.get(cookies.USER_TOKEN);
    }
    return headers;
  }

  bulk(array) {
    const requests = array.map((req, index) => ({
      id: index,
      jsonrpc: '2.0',
      ...req,
    }));

    return this.fetcher.post({
      url: this.endpoint,
      body: requests,
    });
  }

  public async post<Response = any>(
    method: string,
    params?,
    withAuthHeader = true,
    withCaptcha = false,
  ) {
    const body = {
      id: this.ai += 1,
      jsonrpc: '2.0',
      method,
      params,
    };

    const headers = this.buildHeaders(withAuthHeader);
    if (withCaptcha && !env.IS_LOCAL) {
      headers['X-CAPTCHA'] = await CaptchaV3.getToken(method);
    }

    return this.fetcher.post<Response>({
      url: env.IS_DEVELOPMENT ? `${this.endpoint}?${method}` : this.endpoint,
      body,
      headers,
      rpcMethod: method,
      forceUpdate: params && params.forceUpdate,
    });
  }

  public get(method: string, params, withAuthHeader = true) {
    const body = {
      id: this.ai += 1,
      jsonrpc: '2.0',
      method,
      params,
    };

    return this.fetcher.get({
      url: this.endpoint,
      body,
      headers: this.buildHeaders(withAuthHeader),
      rpcMethod: method,
    });
  }
}
