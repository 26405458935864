import * as urls from 'const/urls';
import loadable from '@loadable/component';
import { Loading } from 'ui/Loading/Loading';
import React from 'react';

const loadableOptions = {
  fallback: React.createElement(Loading),
};

export const routeList = {
  /* Модули */
  modules: {
    homePage: {
      path: urls.HOME,
      component: loadable(async () => (await import('modules/Home')).Home),
      exact: true,
    },
    login: {
      path: urls.LOGIN,
      component: loadable(async () => (await import('modules/LoginPage/LoginRouter')).LoginRouter),
    },
    changePhone: {
      path: urls.CHANGE_PHONE,
      component: loadable(async () => (await import('modules/ChangePhone')).ChangePhone),
    },
    addCardSuccess: {
      path: urls.ADD_CARD_TINKOFF_SUCCESS,
      component: loadable(async () => (await import('modules/AddCard/Success')).AddCardSuccess),
      exact: true,
    },
    addCardFail: {
      path: urls.ADD_CARD_TINKOFF_FAIL,
      component: loadable(async () => (await import('modules/AddCard/Fail')).AddCardFail),
      exact: true,
    },
    notFound: {
      path: urls.NOT_FOUND,
      component: loadable(async () => (await import('modules/NotFound')).NotFound),
      exact: true,
    },
    creditHolidaysActive: {
      path: urls.CREDIT_HOLIDAYS_ACTIVE,
      component: loadable(
        async () => (await import('modules/CreditHolidays')).ActivePage,
        loadableOptions,
      ),
      exact: true,
    },
    takeOuterLoan: {
      path: urls.TAKE_OUTER_LOAN,
      component: loadable(
        async () => (await import('modules/TakeOuterLoan')).View,
        loadableOptions,
      ),
      exact: true,
    },
    authComplete: {
      path: urls.AUTH_COMPLETE,
      component: loadable(async () => (await import('modules/AuthComplete')).AuthComplete),
      exact: true,
    },
    authStart: {
      path: urls.AUTH_START,
      component: loadable(async () => (await import('modules/AuthStart')).AuthStart),
      exact: true,
    },
  },
  modulesWithMenu: {
    dashboard: {
      path: urls.DASHBOARD,
      component: loadable(
        async () => (await import('modules/Dashboard')).Dashboard,
        loadableOptions,
      ),
      exact: true,
    },
    events: {
      path: urls.EVENTS,
      component: loadable(async () => (await import('modules/Events')).Events, loadableOptions),
      exact: true,
    },
    // offices: {
    //   path: urls.OFFICES,
    //   component: loadable(async () => (await import('modules/Offices')).Offices, loadableOptions),
    //   exact: true,
    // },
    takeLoan: {
      path: urls.TAKE_LOAN,
      component: loadable(async () => (await import('modules/TakeLoan')).TakeLoan, loadableOptions),
    },
    profile: {
      path: urls.PROFILE,
      component: loadable(async () => (await import('modules/Profile')).Profile, loadableOptions),
    },
    // additionalServices: {
    //   path: urls.ADDITIONAL_SERVICES,
    //   component: loadable(
    //     async () => (await import('modules/AdditionalServices')).AdditionalServices,
    //     loadableOptions,
    //   ),
    // },
    legalSupport: {
      path: urls.LEGAL_SUPPORT,
      component: loadable(async () => (await import('modules/LegalSupport')).LegalSupport),
    },
    loanSubscription: {
      path: urls.LOAN_SUBSCRIPTION,
      component: loadable(async () => (await import('modules/LoanSubscription')).LoanSubscription),
    },
    additionalIdentification: {
      path: urls.ADDITIONAL_IDENTIFICATION,
      component: loadable(
        async () => (await import('modules/AdditionalIdentification')).AdditionalIdentification,
        loadableOptions,
      ),
      exact: true,
    },
    promotions: {
      path: urls.PROMOTIONS,
      component: loadable(
        async () => (await import('modules/Promotions')).BonusProgram,
        loadableOptions,
      ),
      exact: true,
    },
    creditRating: {
      path: urls.CREDIT_RATING,
      component: loadable(
        async () => (await import('modules/CreditRating')).CreditRating,
        loadableOptions,
      ),
      exact: true,
    },
    bonusProgram: {
      path: urls.BONUS_PROGRAM,
      component: loadable(
        async () => (await import('modules/BonusProgram')).BonusProgram,
        loadableOptions,
      ),
      exact: true,
    },
    addCard: {
      path: urls.ADD_CARD,
      component: loadable(async () => (await import('modules/AddCard')).AddCard, loadableOptions),
      exact: true,
    },
    restructure: {
      path: urls.RESTRUCTURE,
      component: loadable(async () => (await import('modules/Restructure')).Sign, loadableOptions),
      exact: true,
    },
    creditHolidays: {
      path: urls.CREDIT_HOLIDAYS_CALCULATOR,
      component: loadable(
        async () => (await import('modules/CreditHolidays')).CalculatorPage,
        loadableOptions,
      ),
      exact: true,
    },
    creditHolidaysDocuments: {
      path: urls.CREDIT_HOLIDAYS_DOCUMENTS,
      component: loadable(
        async () => (await import('modules/CreditHolidays')).DocumentsPage,
        loadableOptions,
      ),
      exact: true,
    },
    serviceRefusal: {
      path: urls.SERVICE_REFUSAL,
      component: loadable(
        async () => (await import('modules/ServiceRefusalNew')).Page,
        loadableOptions,
      ),
      exact: true,
    },
    serviceRefusalBank: {
      path: urls.SERVICE_REFUSAL_BANK,
      component: loadable(
        async () => (await import('modules/ServiceRefusalNew')).PageBank,
        loadableOptions,
      ),
      exact: true,
    },
  },
};
