import * as env from 'const/env';

// Home page
export const HOME = '/';

// Login
export const LOGIN = '/login';

// Login A/B
export const LOGIN_TAKE_LOAN = '/login/take-loan';

export const LOGIN_TAKE_SERVICE = '/login/take-service';

// Change phone
export const CHANGE_PHONE = '/change-phone';

// 404 error - page not found
export const NOT_FOUND = '/404';

export const CREDIT_HOLIDAYS = '/credit-holidays';
export const CREDIT_HOLIDAYS_CALCULATOR = `${CREDIT_HOLIDAYS}`;
export const CREDIT_HOLIDAYS_DOCUMENTS = `${CREDIT_HOLIDAYS}/documents`;
export const CREDIT_HOLIDAYS_ACTIVE = `${CREDIT_HOLIDAYS}/active`;

// Different pages
export const DASHBOARD = '/dashboard';
export const OFFICES = '/offices';
export const ADDITIONAL_IDENTIFICATION = '/additional-identification';
export const ADD_CARD = '/add-card';
export const BONUS_PROGRAM = '/bonus-program';
export const CREDIT_RATING = '/credit-rating';
export const PROMOTIONS = '/promotions';
export const PROFILE = '/profile';
export const PROFILE_CHANGE_PASSWORD = '/profile/change-password';
export const EVENTS = '/events';

// Tinkoff frame callback urls
export const ADD_CARD_TINKOFF_SUCCESS = '/add-card/success';
export const ADD_CARD_TINKOFF_FAIL = '/add-card/fail';

// Take loan flow
export const TAKE_LOAN = '/take-loan';
export const TAKE_LOAN_STEP_1 = '/take-loan/step1';
export const TAKE_LOAN_STEP_2 = '/take-loan/step2';
export const TAKE_LOAN_STEP_3 = '/take-loan/step3';
export const TAKE_LOAN_CLIENT_IDENTIFICATION = '/take-loan/client-identification';
export const TAKE_LOAN_UNDERWRITING = '/take-loan/underwriting';
export const TAKE_LOAN_SIGN_CONTRACT = '/take-loan/sign-contract';
export const TAKE_LOAN_CHOOSE_PAYMENT_METHOD = '/take-loan/choose-payment-method';
export const TAKE_LOAN_CHOOSE_PAYMENT_METHOD_NEW_CARD = '/take-loan/choose-payment-method/new-card';
export const TAKE_LOAN_REPEAT_APPLICATION = '/take-loan/repeat';
export const TAKE_LOAN_PAID_OUT = '/take-loan/paid-out';

// urls.TAKE_LOAN_ADD_PHOTOS removed from application

// ADDITIONAL SERVICES
export const ADDITIONAL_SERVICES = '/additional-services';
export const ADDITIONAL_SERVICES_SIGN = '/additional-services/sign';
export const ADDITIONAL_SERVICES_SUCCESS = '/additional-services/success';
export const ADDITIONAL_SERVICES_FAIL = '/additional-services/fail';
export const ADDITIONAL_SERVICES_REFUSED_SIGN = '/additional-services/refused/sign';
export const ADDITIONAL_SERVICES_REASON_OF_REFUSAL = '/additional-services/reason-of-refusal';

export const LEGAL_SUPPORT = '/legal-support';
export const LOAN_SUBSCRIPTION = '/loan-subscription';
export const RESTRUCTURE = '/restructure';

// DZP PAGES AND DOCS
export const DZP_DASHBOARD = '/dashboard';
export const DZP = 'https://dozarplati.com/';
export const DZP_COMPLAIN = `//${env.PUBLIC_DOMAIN}/complain`;
export const DZP_AGREEMENT = `//${env.PUBLIC_DOMAIN}/components/Landing/Documentation/Documentation/Согласие на обработку персональных данных_all.pdf`;
export const DZP_PROMO_PROFITABLE_PROLONGATION =
  'https://dozarplati.com/components/Landing/Documentation/Documentation/promo/vyigodnaya_prolongatsiya.pdf';

export const DZP_ACCEPTANCE_PROLONGATION = '/acceptance/prolongation';
export const DZP_ACCEPTANCE_PAYMENT = '/acceptance/amount';

export const DZP_UNDER_VIDEO = 'https://dozarplati.com/video/03dd2eb404.1080.mp4';

// export const DZP_SOCIAL_FB = 'https://www.facebook.com/pg/dozarplaticom';
export const DZP_SOCIAL_VK = 'https://vk.com/dozarplaticom';
export const DZP_SOCIAL_OK = 'https://ok.ru/group/58319146844197';

export const DZP_GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.dozarplati.android';
export const DZP_APP_STORE =
  'https://itunes.apple.com/us/app/%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D0%B7%D0%B0%D0%B9%D0%BC%D1%8B-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD-%D0%B4%D0%BE-%D0%B7%D0%B0%D1%80%D0%BF%D0%BB%D0%B0%D1%82%D1%8B/id1227032255?l=ru&ls=1&mt=8';

export const DZP_ABOUT = '/about';
export const DZP_REVIEWS = '/reviews';
export const DZP_DOCUMENTS = '/documents';
export const DZP_SECURITY = '/security';
export const DZP_FAQ = '/faq';
export const DZP_GET = '/how-to-get';
export const DZP_CARD = '/how-to-get#card';
export const DZP_CASH = '/how-to-get#cash';
export const DZP_PAY = '/how-to-pay';
export const DZP_PROLONGATION_PROMO = '/acceptance/info';

export const LK_TAKE_LOAN = 'my.dozarplati.com/take-loan';

export const TAKE_OUTER_LOAN = '/take-outer-loan/:id';
export const SERVICE_REFUSAL = '/service-refusal';
export const SERVICE_REFUSAL_BANK = '/service-refusal/bank';

/**
 * Страница на которою редиректит сервиc, после прохождения аутентификации.
 */
export const AUTH_COMPLETE = '/auth/complete';

/**
 * Страница для обхода блокировки всплывающего окна в Cафари.
 */
export const AUTH_START = '/auth/start';
